import { useRef, useState } from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import Conditional from 'Components/common/conditional';

import { ChevronDownSvg } from 'Assets/svg/desktop/chevronDown';

import useCaptureClickOutside from 'Hooks/useCaptureClickOutside';

import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

const StyledDropdownContainer = styled.div`
	position: relative;
	.dropdown-toggle {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		gap: 0.75rem;
		border-radius: 4px;
		padding: 0.375rem 0.5rem;
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM)}
		color: ${colors.GREY_DS.G2};
		&:hover,
		&.open {
			background: ${colors.GREY.F8};
		}

		svg {
			max-width: 10px;
			margin-left: 6px;
		}
	}
	ul.dropdown-menu {
		position: absolute;
		top: calc(100% + 4px);
		right: 0;
		width: 12rem;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		background-color: ${colors.WHITE};
		border-radius: 4px;
		max-height: 12.5rem;
		overflow-y: scroll;
		box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1),
			0px 2px 8px rgba(0, 0, 0, 0.1);
		li.dropdown-item {
			color: ${colors.GREY_DS.G2};
			padding: 0.75rem;
			cursor: pointer;
			&:hover {
				background-color: ${colors.GREY_DS.G8};
			}
			&.selected {
				color: ${colors.BRAND_COLORS.PURPS};
				background-color: ${colors.PURPS_LEVEL[10]};
			}
		}
	}
`;

export type DropdownOption = {
	label: string;
	value: number;
};
type DropdownProps = {
	options: DropdownOption[];
	selectedOption: DropdownOption;
	onOptionSelect: (option: DropdownOption) => void;
};

export const DateSelectorDropdown = ({
	options,
	selectedOption,
	onOptionSelect,
}: DropdownProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);
	useCaptureClickOutside(dropdownRef, () => setIsOpen(false));

	const handleOptionClick = (option: DropdownOption) => {
		onOptionSelect(option);
		setIsOpen(false);
	};

	return (
		<StyledDropdownContainer ref={dropdownRef} className='dropdown'>
			<button
				className={'dropdown-toggle ' + (isOpen ? 'open' : '')}
				onClick={() => setIsOpen(!isOpen)}
			>
				{selectedOption?.label ?? strings.CMN_SELECT}
				<ChevronDownSvg
					style={{ ...(isOpen && { transform: `rotate(180deg)` }) }}
				/>
			</button>
			<Conditional if={isOpen}>
				<ul className='dropdown-menu'>
					{options.map(option => (
						<li
							key={option.value}
							className={
								'dropdown-item ' +
								(selectedOption?.value === option.value
									? 'selected'
									: '')
							}
							onClick={() => handleOptionClick(option)}
						>
							{option.label}
						</li>
					))}
				</ul>
			</Conditional>
		</StyledDropdownContainer>
	);
};

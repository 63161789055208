import { useMemo, useState } from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import Conditional from 'Components/common/conditional';

import {
	checkIfDateInBetweenDateRange,
	getHumanReadableDate,
} from 'Utils/dateUtils';
import dayjs from 'Utils/dayjsUtil';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

import { DateSelectorDropdown, DropdownOption } from './dateSelectorDropdown';
import Table from './table';
import { TOperatingHoursSchedule, TProps } from './type';

export const TableOutline = styled.div`
	border: 1px solid ${colors.GREY_DS.G6};
	border-radius: 8px;
	width: 100%;
	margin-bottom: 1.5rem;

	@media (min-width: 768px) {
		border-radius: 12px;
		width: max-content;
	}
`;
const TableHeadingContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 2rem;
	border-bottom: 1px solid ${colors.GREY_DS.G6};
`;
const TableHeading = styled.h4`
	color: ${colors.GREY_DS.G2};
	${getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_LARGE)}
`;

export const createDateRangeList = (
	operatingSchedules: TOperatingHoursSchedule[],
	lang?: string,
) =>
	operatingSchedules.map(
		(schedule: TOperatingHoursSchedule, index: number) => ({
			value: index,
			label:
				getHumanReadableDate(schedule.startDate, lang) +
				' - ' +
				getHumanReadableDate(schedule.endDate, lang),
		}),
	);

export const isSameWeekday = (
	day: string,
	short?: boolean,
	timezone?: string,
) => {
	return (
		dayjs()
			.tz(timezone)
			.format(short ? 'ddd' : 'dddd')
			.toLowerCase() === day.toLowerCase()
	);
};

const OperatingHoursTable = ({ data, lang, timezone }: TProps) => {
	const { operatingSchedules, name } = data;
	const [dateRangeIndex, setDateRangeIndex] = useState(0);

	const handleDateRangeChange = (option: DropdownOption) => {
		setDateRangeIndex(option.value);
	};

	const dateRangeList = useMemo(
		() => createDateRangeList(operatingSchedules, lang),
		[operatingSchedules, lang],
	);
	const { operatingDaySchedules } = operatingSchedules[dateRangeIndex];
	const isCurrentSchedule =
		dateRangeIndex === 0 &&
		checkIfDateInBetweenDateRange({
			date: dayjs().tz(timezone).toString(),
			startDate: operatingSchedules[0].startDate,
			endDate: operatingSchedules[0].endDate,
		});
	const hideDateRangeSelector =
		dateRangeList?.length <= 1 && isCurrentSchedule;

	return (
		<TableOutline>
			<TableHeadingContainer>
				<TableHeading>{name}</TableHeading>
				<Conditional if={!hideDateRangeSelector}>
					<DateSelectorDropdown
						options={dateRangeList}
						selectedOption={dateRangeList[dateRangeIndex]}
						onOptionSelect={handleDateRangeChange}
					/>
				</Conditional>
			</TableHeadingContainer>
			<Table
				operatingDaySchedules={operatingDaySchedules}
				isActiveSchedule={isCurrentSchedule}
				lang={lang}
			/>
		</TableOutline>
	);
};

export default OperatingHoursTable;

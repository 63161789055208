import React, { useMemo } from 'react';
import classNames from 'classnames';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import Conditional from 'Components/common/conditional';
import { isSameWeekday } from 'Components/desktop/operatingHoursTable';
import type { TOperatingDaySchedule } from 'Components/desktop/operatingHoursTable/type';

import { getHumanReadableTime, getWeekdayLocaleMap } from 'Utils/dateUtils';

import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

const StyledTable = styled.table`
	.oh-dweb-tbody {
		padding: 1.5rem 2rem 2rem;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		color: ${colors.GREY_DS.G3};

		.oh-dweb-tr {
			${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR)}
			display: flex;
			gap: 1.5rem;

			td {
				width: 13.75rem;
			}

			&.active {
				font-weight: 500;
				color: ${colors.PURPS_DARK};
			}
		}
	}
`;
const Table = ({
	operatingDaySchedules = [],
	isActiveSchedule,
	lang,
	timezone,
}: {
	operatingDaySchedules: TOperatingDaySchedule[];
	isActiveSchedule: boolean;
	lang?: string;
	timezone?: string;
}) => {
	const localeMap = useMemo(
		() => getWeekdayLocaleMap({ currentLang: lang }),
		[lang],
	);

	return (
		<StyledTable>
			<tbody className='oh-dweb-tbody'>
				{operatingDaySchedules.map((dayObj, index) => {
					const localeWeekday =
						localeMap[dayObj.dayOfWeek.toLowerCase()];
					const isActive =
						isActiveSchedule &&
						isSameWeekday(localeWeekday, undefined, timezone);

					let operationalHrTime;
					if (dayObj.openingTime && dayObj.closingTime)
						operationalHrTime =
							getHumanReadableTime(dayObj.openingTime, lang) +
							' - ' +
							getHumanReadableTime(dayObj.closingTime, lang);

					return (
						<tr
							className={classNames({
								'oh-dweb-tr': true,
								active: isActive,
							})}
							key={index + dayObj.dayOfWeek}
						>
							<td>{localeWeekday}</td>
							<td>
								{dayObj.closed
									? strings.CLOSED
									: operationalHrTime}
							</td>
							<td>
								<Conditional if={dayObj.lastEntryTime}>
									{strings.LAST_ENTRY}{' '}
									{getHumanReadableTime(
										dayObj.lastEntryTime as string,
									)}
								</Conditional>
							</td>
						</tr>
					);
				})}
			</tbody>
		</StyledTable>
	);
};

export default Table;

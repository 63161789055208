import * as React from 'react';

export const ChevronDownSvg = (props: any) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			fill='none'
			viewBox='0 0 16 16'
			{...props}
		>
			<path
				stroke='#444'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='2'
				d='M1.333 4.667L8 11.333l6.666-6.666'
			></path>
		</svg>
	);
};
